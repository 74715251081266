import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CProcessType, RespOnProcessDoc} from '../../../_domains/UITypes';
import {UploaderComponent} from '../../uploader/uploader.component';
import {DocumentUtilsPrivateService} from '../_services/document-utils-private.service';
import {TemplateUtilsPrivateService} from '../_services/template-utils.service';
import {ViewDictPrivateService} from '../_services/view-dict.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {ExportUtilsService} from '../_services/export-utils.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {AuthService} from '../../../_services/auth.service';
import {ConfigService} from '../../../_services/config.service';
import {ClipboardService} from 'ngx-clipboard';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {UIUtils} from '../../../_utils/ui-utils';
import {DocumentEditorComponent} from '../document-editor/document-editor.component';
import {Dialog} from 'primeng/dialog';
import * as moment from 'moment';
import {OrderListComponent} from '../orderlist/orderlist.component';
import {showDynamicComponent} from '../_services/document-utils';
import {DynamicDirective} from '../../../_directives/dynamic.directive';
import {firstValueFrom} from 'rxjs';

declare var $: any;
declare var pz: any;
declare var DocumentClear: any;

@Component({
  selector: 'app-dialog-doceditor',
  templateUrl: './dialog-report-editor.component.html',
  styleUrls: ['../_css/documentEditor.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DocumentUtilsPrivateService,
    ViewDictPrivateService,
    TemplateUtilsPrivateService,
    ExportUtilsService]
})
export class DialogReportEditorComponent implements OnInit {
  @Output() onProcess = new EventEmitter<RespOnProcessDoc>();
  @Input() classToolbar: string = '';
  @Input() classContent: string = '';
  @Input('dataMode') idDataMode = 0;
  @Input('idDoc') inIdDoc: string = '';
  @Input('cdoc') inCdoc: string = '';
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild('dlg') dlg: Dialog;
  @ViewChild('rowEditDialog') rowEditDialogCmp: Dialog;

  previewShow: boolean = false;
  popupShow: boolean = false;
  popupHeader: string = '';
  popupContent: string = '';
  popupType = 'info';
  listDctDocs: any[] = [];
  listSubdocs: any[] = [];
  activeMenuId: string;
  processing: boolean = false;

  private idpar: string;
  iddoc: string;
  cdoc: string = '';
  private access: any = null;
  private editable: boolean = false;
  private fileRowId;
  private docState: number = 0;
  private grType: number = 0;
  rowEditDialogVisible: boolean;
  dataMode = 0;
  private _visible = false;
  cmpVisible = false;
  docsItems = [];
  private isPackage = false;
  contentStyle!: any;
  showBtnSave = false;
  showBtnCheck = false;
  showBtnReset = false;
  showBtnRemove = false;
  showBtnDownload = false;
  showBtnPrint = false;
  showBtnCopyLink = false;

  @ViewChild(UploaderComponent, {static: false}) uploaderCmp;
  @ViewChild(OrderListComponent, {static: false}) orderlistCmp;
  @ViewChild(DocumentEditorComponent, {static: false}) editorCmp;
  @ViewChild(DynamicDirective, {static: true}) private dynamicTemplate!: DynamicDirective;

  constructor(private docService: DocumentUtilsPrivateService,
              private utlService: TemplateUtilsPrivateService,
              private dictService: ViewDictPrivateService,
              private messageService: AppMessageService,
              private exportService: ExportUtilsService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private _configService: ConfigService,
              private clipboardService: ClipboardService,
              private ngZone: NgZone,
              private translate: TranslateService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible && !this.cmpVisible && this.inIdDoc) {
      this.refreshVars(this.inIdDoc, this.idDataMode);
      this.cmpVisible = true;
    } else if (!this._visible && this.cmpVisible) {
      this.cmpVisible = false;
    }
  }

  onHide() {
    let _self = this;
    if (this.editable) {
      const _isCommitted = this.editorCmp.isCommitted();
      if (_isCommitted == false && this.access.accessWrite) {
        _self.confirmationService.confirm({
          message: this.translate.instant('message.document.saveProgress'),
          header: this.translate.instant('message.header.confirmation'),
          icon: this.configService.isDesktopDevice ? 'fa fa-question-circle' : '',
          accept: () => _self.save(false, true),
          reject: () => _self.editorCmp.deattachCmp()
        });
      } else { // (_isCommitted == false && this.access.accessWrite)
        _self.editorCmp.deattachCmp();
      }
    } else { // (this.editable)
      _self.editorCmp.deattachCmp();
    }
    this.visibleChange.emit(false);
  }

  onResize() {
    this.contentStyle = {'height': `${window.innerHeight - (this.configService.isMobileDevice || this.dlg.maximized ? 120 : 255)}px`};
    this.cd.detectChanges();
  }

  refreshVars(id: string, dataMode: number) {
    this.idpar = null;
    let _self = this;
    this.dataMode = dataMode;
    this.docsItems = [];
    this.isPackage = false;
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    $.extend($.jdm, {
      //
      isMobileDevice: function () {
        return _self.configService.deviceType > 0;
      },
      //
      save: function () {
      },
      //
      saveWithoutValidating: function () {
        _self.ngZone.run(() => {
          _self.saveWithoutValidating();
        });
      },
      //
      saveWitValidating: function () {
        _self.ngZone.run(() => {
          _self.save(true);
        });
      },
      // submit data
      submit: function () {
        _self.ngZone.run(() => {
          _self.send();
        });
      },
      // confirm dialog
      confirm: function (title, message, clbYes, clbNo) {
        let clbAccept = typeof (clbYes) !== 'undefined' ? clbYes : null;
        let clbReject = typeof (clbNo) !== 'undefined' ? clbNo : null;
        _self.ngZone.run(() => {
          _self.confirmationService.confirm({
            message: message,
            header: title,
            icon: _self.configService.isDesktopDevice ? 'fa fa-question-circle' : '',
            accept: () => {
              clbAccept();
            },
            reject: () => {
              clbReject();
            }
          });
        });
      },
      // info-dialog
      info_dialog: function (title, message, type) {
        _self.ngZone.run(() => {
          switch (type) {
            case 'error':
              _self.messageService.showError(message, title);
              break;
            case 'info':
              _self.messageService.showSuccess(message, title);
              break;
            default:
              _self.messageService.showWarn(message, title);
              break;
          }
        });
      },
      // open-dialog
      open_dialog: function (title, content, type?: string) {
        _self.ngZone.run(() => {
          _self.popupHeader = title;
          _self.popupContent = content;
          _self.popupShow = true;
          _self.popupType = type || 'info';
          _self.cd.detectChanges();
        });
      },
      // get country record
      getCountryRecord: function (code, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getStateRec(code).then(data => {
            if (clb) clb(data);
          });
        });
      },
      showUploadForm: function (rowId) {
        _self.ngZone.run(() => {
          _self.showUploadForm(rowId);
        });
      },
      // show Ordered list picker
      showOrderListForm: function (rowId) {
        _self.ngZone.run(() => {
          _self.showOrderListForm(rowId);
          _self.cd.detectChanges();
        });
      },
      // remove item from order list
      removeItemOrderListForm: function (rowId, val2del) {
        _self.ngZone.run(() => {
          _self.removeItemOrderListForm(rowId, val2del);
        });
      },
      // upload file data
      uploadFileData: function (rowId, fileData) {
        _self.ngZone.run(() => {
          _self.uploadFileData(rowId, fileData);
        });
      },
      // clear files data
      clearFileData: function (rowId) {
        _self.ngZone.run(() => {
          _self.clearFileData(rowId);
        });
      },
      // download files data
      downloadFileData: function (id) {
        _self.ngZone.run(() => {
          _self.downloadFileData(id);
        });
      },
      // get dictionary data
      getDictData: function (dictName, valueField, parentDictName, parentValueField, parentValue, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getDictData(dictName, valueField, parentDictName, parentValueField, parentValue).then(data => {
            if (clb) clb(data);
          });
        });
      },
      showRowEditDialog: function (clbShow, clbHide) {
        _self.ngZone.run(() => {
          _self.showRowEditDialog(clbShow, clbHide);
        });
      },
      showRichTextEditor: function (rowId, value) {
        _self.ngZone.run(() => {
          showDynamicComponent(_self, {rowId, value});
          _self.cd.detectChanges();
        });
      }
    });

    this.initParams(id, this.inCdoc, true);
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
  }

  private async initParams(id: string, cdoc: string, needFillSubDocs: boolean) {
    this.iddoc = id;
    this.cdoc = '*';
    this.editable = false;
    this.access = null;
    this.docState = 0;
    this.grType = 0;

    const docStatus = await this.docService.getDocumentStatus(this.iddoc, cdoc);
    if (docStatus) {
      if (docStatus.errorCode) {
        this.messageService.showWarn(docStatus.message);
        return;
      } else {
        if (this.iddoc !== '-1') {
          this.idpar = docStatus.idPar;
        }
        this.activeMenuId = docStatus.cdoc;
        this.cdoc = docStatus.cdoc;
        this.editable = docStatus.editable;
        this.access = docStatus.optsAcc || {};
        this.docState = docStatus.docState || 0;
        // Retrieving additional form record, parameters, rules, groups
        const tmplVars = await this.utlService.getFormTemplateVars(this.cdoc);
        const dctDoc = tmplVars.sprdoc;
        this.grType = dctDoc.project && dctDoc.project.grType ? dctDoc.project.grType : 0;
        if (needFillSubDocs && !this.idpar && dctDoc?.project?.cdoc?.length > 0) {
          await this.fillSubDocs(dctDoc);
          this.isPackage = this.listDctDocs.length > 1 && this.listDctDocs.find(v => v.cdoc === this.cdoc);
        } else {
          DocumentClear();
        }

        let _self = this;
        const callbackGetData = (success, failure): void => {
          _self.ngZone.run(() => {
            _self.callbackGetRptData(_self.iddoc, _self.idpar, _self.cdoc, success, failure)
          });
        }
        // this.editorCmp.deattachCmp();
        if (dctDoc.style) {
          $('.dialog-wrapper, .dialog-wrapper .p-toolbar').css(dctDoc.style);
        } else {
          $('.dialog-wrapper, .dialog-wrapper .p-toolbar').removeAttr('style');
        }
        this.editorCmp.refreshVars(
          this.iddoc, // iddoc
          docStatus, // the document status
          tmplVars, // template vars
          {
            id: this.authService.user.id || '?',
            email: this.authService.user.email || '?',
            username: this.authService.user.username || '?',
            fullname: this.authService.user.fullname || '?',
            orgLevel: docStatus?.optsAcc?.orgLevel
          }, // auth record
          callbackGetData // callback
        );

        this.initButtonsVisibility();

        this.cd.detectChanges();
      }
    }
  }

  private initButtonsVisibility() {
    this.showBtnSave = !this.previewShow && this.editable && this.access?.accessWrite;
    this.showBtnCheck = !this.previewShow && !this.cdoc?.startsWith('TBW') && this.editable && this.access?.accessWrite;
    this.showBtnRemove = !this.previewShow && this.access?.accessRemove && this.iddoc !== '-1';
    this.showBtnReset = !this.previewShow && this.docState >= 2 && this.access?.accessReopen && this.iddoc !== '-1';
    this.showBtnDownload = !this.previewShow && this.access?.roleDownload && this.iddoc !== '-1';
    this.showBtnPrint = this.access?.rolePrint;
    this.showBtnCopyLink = !this.previewShow && this.docState < 2 && this.grType == 2; /* SURVEY ^*/
  }

  async fillSubDocs(dctDoc: any) {
    this.listDctDocs = [];
    this.listSubdocs = [];
    this.listDctDocs.push({docName: dctDoc.name, docLabel: dctDoc.name, cdoc: dctDoc.cdoc, main: true});

    const data_2 = await firstValueFrom(this.utlService.getListAppendixDocs(this.cdoc));

    for (let i in data_2) {
      const rdata = data_2[i];
      if (rdata.state === 1) {
        this.listDctDocs.push({
          docName: ('' + rdata.docName),
          docLabel: ('' + rdata.docName).replace(/\[\[RPT_FIELD:(\w+)]]/, ''),
          cdoc: rdata.cdoc,
          multiple: !rdata.multiple ? 0 : rdata.multiple
        });
      }
    }
    await this.fillDocItems();
  }

  async fillDocItems() {
    this.docsItems = [];
    const idPar = !this.idpar ? this.iddoc : this.idpar;
    this.listSubdocs = [{iddoc: idPar, cdoc: this.listDctDocs.find(v => v.main)?.cdoc}];
    let subDocs = await this.docService.getListSubdocs(idPar);
    if (subDocs.length) {
      this.listSubdocs = this.listSubdocs.concat(subDocs);
    }
    for (const dctDoc of this.listDctDocs) {
      const items = this.listSubdocs.filter(v => v.cdoc === dctDoc.cdoc);
      this.docsItems.push({
        cdoc: dctDoc.cdoc,
        docName: dctDoc.docName,
        docLabel: dctDoc.docLabel,
        main: dctDoc.main,
        addVisible: !dctDoc.main && (!items?.length || items?.length < dctDoc.multiple),
        items: items
      });
    }
    this.cd.detectChanges();
  }

  async checkNeedCreateBeforeSave() {
    if (this.iddoc === '-1') {
      try {
        const res = await firstValueFrom(this.docService.createEmptyReport(this.cdoc, this.dataMode));
        if (res.status === 1) {
          this.iddoc = res.message;
          return true;
        } else {
          this.messageService.showError(res.message);
          return false;
        }
      } catch (e) {
        this.messageService.showMessage(e);
        return false;
      }
    }
    return true;
  }

  async save(outputCheck: boolean, needDetach?: boolean) {
    this.processing = true;
    //
    this.editorCmp.commit();
    this.editorCmp.reset();

    let iddoc = this.iddoc;

    //
    this.docService.save(
      this.idpar,
      iddoc,
      this.cdoc,
      this.editorCmp.dynamicVars(),
      this.editorCmp.data(),
      (this.editorCmp.check()).errors
    )
      .pipe(finalize(() => {
        this.processing = false;
        this.cd.detectChanges();
      }))
      .subscribe({
        next: (data) => {
          const res = data;
          if (res.iddoc && res.iddoc !== iddoc) {
            this.iddoc = res.iddoc;
          }
          if ((this.idpar || iddoc === '-1') && this.isPackage) {
            this.fillDocItems();
          }
          if (iddoc === '-1') {
            this.initButtonsVisibility();
          }
          iddoc = this.iddoc;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.saved'));
            (pz.$inst).jqDm('wizardHighlight', []);
            if (iddoc !== '-1') {
              this.onProcess.emit({oper: CProcessType.Save, iddoc: iddoc, status: res.status});
            }
          } else if (res.status === 0) {
            this.messageService.showSuccess(this.translate.instant('message.document.savedWarn', {message: res.message}));
            if (iddoc !== '-1') {
              this.onProcess.emit({oper: CProcessType.Save, iddoc: iddoc, status: res.status, logs: res.logs});
            }
            if (outputCheck) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else if (res.status === -6 || res.status === -7) { // -6 duplicate key, -7 - block saving rule, document not saved
            this.messageService.showInfo(res.message);
            if (iddoc !== '-1') {
              this.onProcess.emit({oper: CProcessType.Save, iddoc: iddoc, status: res.status, logs: res.logs});
            }
            if (res?.logs && outputCheck) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else {
            this.messageService.showError(res.message);
          }
          this.updateUploadedFileData(res);
          if (needDetach) {
            this.editorCmp.deattachCmp()
          }
        },
        error: (error: any) => {
          this.messageService.showMessage(error, '[Save]');
          if (this.fileRowId) {
            this.uploaderCmp.finish(false);
            this.fileRowId = null;
          }
          if (needDetach) {
            this.editorCmp.deattachCmp();
          }
        }
      });
  }

  private updateUploadedFileData(res: any) {
    if (res.status === 1 || res.status === 0) {
      if (this.fileRowId) {
        this.uploaderCmp.finish(true);
        this.editorCmp.setCellValue(this.fileRowId + 'Blocks', '');
        this.fileRowId = null;
      }
    } else {
      if (this.fileRowId) {
        this.uploaderCmp.finish(false);
        this.fileRowId = null;
      }
    }
  }

  async saveWithoutValidating() {
    //
    this.editorCmp.commit();
    //
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }

    this.docService.saveWithoutValidate(this.iddoc, this.editorCmp.dynamicVars(), this.editorCmp.data())
      .subscribe(
        data => {
          const res = data;
          this.updateUploadedFileData(res);
        },
        error => this.messageService.showMessage(error));
  }

  check() {
    this.processing = true;
    //
    this.editorCmp.reset();
    //
    this.docService.check(this.iddoc, this.idpar, this.cdoc, this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.validation.success'));
            (pz.$inst).jqDm('wizardHighlight', []);
            this.onProcess.emit({oper: CProcessType.Check, status: res.status});
          } else if (res.status === 0) {
            this.messageService.showWarn(res.message);
            this.onProcess.emit({oper: CProcessType.Check, status: res.status, logs: res.logs});
            if (res.logs) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else {
            this.messageService.showError(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error, '[Check]');
        });
  }

  async send() {
    this.processing = true;
    //
    // saving before sending
    if (this.access.accessWrite) {
      this.editorCmp.commit();
      this.editorCmp.reset();
      //

      let iddoc = this.iddoc;
      this.docService.save(this.idpar, iddoc, this.cdoc, this.editorCmp.dynamicVars(), this.editorCmp.data(), this.editorCmp.check().errors)
        .subscribe({
          next: (data) => {
            const res = data;
            if (res.iddoc && res.iddoc !== iddoc) {
              iddoc = res.iddoc;
              this.iddoc = res.iddoc;
            }
            this.updateUploadedFileData(res);
            if (res.status !== -6) { // duplicate key, document not saved
              this.doSend(iddoc);
            } else {
              this.messageService.showInfo(res.message);
            }
          },
          error: (error) => this.messageService.showMessage(error)
        });
    } else if (this.idpar !== '-1') {
      this.doSend(this.idpar);
    }
  }

  private doSend(iddoc: string) {
    this.docService.send(iddoc)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            (pz.$inst).jqDm('wizardHighlight', []);
            this.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
            this.onProcess.emit({oper: CProcessType.Send, status: 1, iddoc: iddoc});
            // this.displayDialog = false;
            this.visibleChange.emit(false);
          } else if (res.status === 0) {
            this.messageService.showWarn(this.translate.instant('message.document.submit.warn'));
            this.onProcess.emit({oper: CProcessType.Send, status: 0, iddoc: iddoc});
            this.sendForce(iddoc);
          } else {
            this.messageService.showWarn(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error, '[Send]');
        });
  }

  sendForce(iddoc: string) {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.submit.warnConfirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: this.configService.isDesktopDevice ? 'fa fa-question-circle' : '',
      accept: () => {
        _self.processing = true;
        _self.docService.sendForce(iddoc)
          .pipe(finalize(() => _self.processing = false))
          .subscribe(
            data => {
              let res = data;
              if (res.status === 1) {
                _self.getDocumentStatus();
                _self.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
                _self.onProcess.emit({oper: CProcessType.SendForce, iddoc: iddoc});
              } else {
                _self.messageService.showWarn(res.message);
              }
              this.visibleChange.emit(false);
            },
            error => {
              _self.messageService.showMessage(error);
            });
      },
      reject: () => {
      }
    });
  }

  reset() {
    this.processing = true;
    const iddoc = this.iddoc;
    //
    this.docService.reset(iddoc)
      .pipe(finalize(() => this.processing = false))
      .subscribe(
        data => {
          let res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.reopen.success'));
            this.getDocumentStatus();
            this.onProcess.emit({oper: CProcessType.Reset, iddoc: this.iddoc});
          } else {
            this.messageService.showWarn(res.message);
          }
        },
        error => this.messageService.showMessage(error));
  }

  delete() {
    const iddoc = this.iddoc;
    this.deleteDocument(iddoc);
  }

  deleteDocument(iddoc: string, needClose?: boolean) {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: this.configService.isDesktopDevice ? 'fa fa-question-circle' : '',
      accept: () => {
        _self.processing = true;
        this.editorCmp.reset();
        _self.docService.delete(iddoc)
          .pipe(finalize(() => {
            _self.processing = false;
          }))
          .subscribe(
            data => {
              const res = data;
              if (res.status === 1) {
                _self.messageService.showSuccess(this.translate.instant('message.document.delete.success'));
                if (_self.iddoc) {
                  this.visibleChange.emit(false);
                }
                // if (_self.iddoc && needClose) {
                //   this.visibleChange.emit(false);
                // } else if ((this.idpar || iddoc === '-1') && this.isPackage) {
                //   this.fillDocItems();
                // }
                _self.onProcess.emit({oper: CProcessType.Remove, iddoc: iddoc});
              } else {
                _self.messageService.showWarn(res.message);
              }
            },
            error => {
              _self.messageService.showMessage(error, '[Delete]');
            })
      },
      reject: () => {
      }
    });
  }

  export() {
    const iddoc = this.iddoc;
    this.exportService.exportDocument(iddoc);
  }

  copyLink() {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.copyLink'),
      header: this.translate.instant('message.header.warn'),
      icon: 'fas fa-exclamation',
      accept: () => {
        _self.getDocumentToken();
      },
      reject: () => {
      }
    });
  }

  private getDocumentToken() {
    const iddoc = this.iddoc;
    this.docService.getDocumentToken(iddoc)
      .subscribe(data => {
          this.clipboardService.copy(UIUtils.getPublicDocLink(data.message));
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  print2pdf() {
    this.processing = true;
    const fileName = this.cdoc + '_' + moment().format('YYYYMMDD_HHmmss') + '.pdf';
    const callback = (success: boolean): void => {
      if (!success) {
        this.messageService.showError(this.translate.instant('message.document.print.error'));
      }
      this.processing = false;
    }
    this.editorCmp.print2pdf(fileName, callback);
  }

  restoreEditor() {
    this.previewShow = false;
    this.editorCmp.restoreEditor();
  }

  private callbackGetRptData(iddoc: string, idPar: string, cdoc: string, success: (resp) => any, failure: (error) => any) {
    let clbErr = (error): void => {
      this.messageService.showMessage(error, '[callbackGetRptData]');
      if (typeof failure === 'function') {
        failure(error);
      }
    };

    this.docService.getDocumentJSONData(iddoc, idPar, cdoc, clbErr)
      .then(resp => {
        if (resp) {
          if (resp.access) {
            success(resp.data);
          } else {
            this.messageService.showError(this.translate.instant('message.document.open.notAllowed'));
            success({});
          }
        }
      });
  }

  get isDisabledBtnDocSave(): boolean {
    return this.processing;
  }

  get isDisabledBtnDocCheck(): boolean {
    return this.processing;
  }

  get isDisabledBtnDocRemove(): boolean {
    return this.processing;
  }

  get isDisabledBtnDocExport(): boolean {
    return this.processing;
  }

  get isDisabledBtnDocReset(): boolean {
    return this.processing;
  }

  get isDisabledBtnCopyLink(): boolean {
    return this.processing;
  }

  private getDocumentStatus() {
    const iddoc = this.iddoc;
    this.docService.getDocumentStatus(iddoc, this.cdoc)
      .then(resp => {
        this.access = resp.optsAcc || {};
        this.docState = resp.docState || 0;
        this.editable = resp.editable;
        this.editorCmp.setStatus(resp);
        this.initButtonsVisibility();
        this.cd.detectChanges();
      });
  }

  get configService(): ConfigService {
    return this._configService;
  }

  get items(): MenuItem[] {
    return [
      {
        label: 'Save', icon: 'fa fa-floppy-o', disabled: this.isDisabledBtnDocSave, command: () => {
          this.save(true);
        }
      },
      {
        label: 'Check', icon: 'fa fa-check', disabled: this.isDisabledBtnDocCheck, command: () => {
          this.check();
        }
      },
      {
        label: 'Remove', icon: 'fa fa-trash', disabled: this.isDisabledBtnDocRemove, command: () => {
          this.delete();
        }
      },
      {
        label: 'Export', icon: 'fa fa-download', disabled: this.isDisabledBtnDocExport, command: () => {
          this.export();
        }
      },
      {separator: true},
      {
        label: 'Refresh', icon: 'fa fa-refresh', command: () => {/* this.reload()*/
        }
      },
    ]
  }

  get dialogStyle(): any {
    const width = window.innerWidth -
    window.innerWidth <= 1024 ? window.innerWidth - 100 : window.innerWidth * 0.8;
    return {height: `${window.innerHeight - 135}px`, width: `${width}px`}
  }

  getToolbarClass(): string {
    return this.classToolbar.length > 0 ? this.classToolbar : '';
  }

  getContentClass(): string {
    return this.classContent.length > 0 ? this.classContent : '';
  }

  isNewDocument(rdata: any): boolean {
    const _cdoc = rdata.cdoc;
    let _open: boolean = true;
    for (let i in this.listSubdocs) {
      const rdata = this.listSubdocs[i];
      if (rdata.cdoc === _cdoc) {
        _open = false;
        break;
      }
    }
    return _open;
  }

  get addDocumentButtonDisabled(): boolean {
    return !this.idpar && this.iddoc === '-1';
  }

  addDocument(cdoc: string) {
    if (this.addDocumentButtonDisabled) {
      this.messageService.showInfo(this.translate.instant('message.document.subDoc.create.main.notSaved'));
      return;
    }
    const idPar = !this.idpar ? this.iddoc : this.idpar;
    this.docService.createAppendix(idPar, cdoc)
      .subscribe(ret => {
        if (ret.status === 1) {
          this.idpar = idPar;
          const iddoc: string = ret.message;
          this.initParams(iddoc, cdoc, false);
        } else {
          this.messageService.showError(ret.message);
        }
      });
  }

  showDocument(iddoc: string, cdoc: string) {
    if (this.iddoc === iddoc) {
      return;
    }
    this.initParams(iddoc, cdoc, false);
  }

  clickActiveMenu(data: any) {
    const _cdoc = data.cdoc, _docName = ('' + data.docName).toLowerCase();
    let open = true;
    // for (let i in this.listSubdocs) {
    //   const rdata = this.listSubdocs[i];
    //   if (rdata.cdoc === _cdoc) {
    //     _open = false;
    //     this.activeMenuId = (this.activeMenuId === `${_cdoc}` ? null : `${_cdoc}`);
    //     this._getDocTemplate(rdata.iddoc);
    //     break;
    //   }
    // }
    if (open) {
      if (this.editable) {
        this.confirmationService.confirm({
          message: this.translate.instant('message.document.open.notExistsCreate', {docName: _docName}),
          header: this.translate.instant('message.header.confirmation'),
          icon: 'fa fa-question-circle',
          accept: () => {
            this.docService.createAppendix(this.idpar, _cdoc)
              .subscribe(_ret => {
                const _iddoc: string = _ret.message;
                this.activeMenuId = (this.activeMenuId === `${_cdoc}` ? null : `${_cdoc}`);
                // this._initExtraVars();
                // this._getDocTemplate(_iddoc);
              });
          }
        });
      }
    }
  }

  async showUploadForm(rowId: string) {
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }
    this.fileRowId = rowId;
    this.uploaderCmp.show(rowId);
  }

  showOrderListForm(rowId: string) {
    this.orderlistCmp.show(rowId)
  }

  removeItemOrderListForm(rowId: string, val2del: string) {
    let cellValue = pz.$inst[0].p.data[0][rowId];
    cellValue = cellValue.replace(new RegExp(`^${val2del}($|;)`), '');
    cellValue = cellValue.replace(new RegExp(`(;${val2del})($|;)`), '$2');
    pz.$inst.jqDm('setCellValue', 0, rowId, cellValue);
  }

  uploadFileData(rowId: string, fileData) {
    this.fileRowId = rowId;
    this.uploaderCmp.show(rowId);
    this.uploaderCmp.fileUploader(fileData);
  }

  clearFileData(rowId: string) {
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.file.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.clearFileDataInt(rowId)
      }
    });
  }

  private clearFileDataInt(rowId: string) {
    this.editorCmp.clearFileDataInt(rowId);
  }

  downloadFileData(id: string) {
    this.docService.getFileLink(this.iddoc, id)
      .subscribe(
        data => {
          window.open(data, '_blank');
        },
        error => {
          this.messageService.showMessage(error, this.translate.instant('message.document.file.download.error'));
        }
      );
  }

  fileUploadSuccess(data: any) {
    this.editorCmp.fileUploadSuccess(this.fileRowId, data);
    this.save(true);
  }

  fileUploadFailed(event: any) {
    this.clearFileDataInt(this.fileRowId);
  }

  orderListSuccess(ret: any) {
    this.editorCmp.orderListSuccess(ret.rowId, ret.data);
  }

  onclose() {
    this.orderlistCmp.close();
  }

  showRowEditDialog(clbShow, clbHide) {
    this.rowEditDialogVisible = true;
    if (clbShow && this.rowEditDialogCmp) {
      this.rowEditDialogCmp.onShow = new EventEmitter<void>();
      this.rowEditDialogCmp.onShow.subscribe(_ => clbShow());
    }
    if (clbHide && this.rowEditDialogCmp) {
      this.rowEditDialogCmp.onHide = new EventEmitter<void>();
      this.rowEditDialogCmp.onHide.subscribe(_ => clbHide());
    }
    this.cd.detectChanges();
  }

  get isDisabledBtnPdf(): boolean {
    return this.processing;
  }

  onShow() {
    if (this.configService.isMobileDevice) {
      this.dlg.maximize();
    } else {
      this.contentStyle = {'height': `${window.innerHeight - 255}px`};
    }
  }

  onMaximize(event) {
    const height = event.maximized ? 120 : 255;
    this.contentStyle = {'height': `${window.innerHeight - height}px`};
  }

  get packagesVisible(): boolean {
    return this.isPackage;
  }

}
