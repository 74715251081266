<p-dialog [header]="header" [(visible)]="dialogVisible" [modal]="true"
          [style]="{ 'width': '80%', 'minWidth': '400px' }">
  <p-table #queryTabData
           [value]="tabData.content"
           [totalRecords]="tabData.totalElements" [rows]="tabData.size"
           [lazy]="true" (onLazyLoad)="handleTablePageChange($event)"
           [loading]="loading$ | async"
           [paginator]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'"
           paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let column of getTableColumns()"> <!-- For .. -->
          <th
            class="header-field datatype-{{column.dataType.toLowerCase()}} header-field-{{column.name.toLowerCase()}}"
            [class.required]="column.required">
            {{column.header | removeHtml | trim:40}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <ng-container *ngFor="let column of getTableColumns()">
          <td class="data-field datatype-{{column.dataType.toLowerCase()}} field-{{column.name.toLowerCase()}}"
              pTooltip="{{row[column.field]}}">
            <a *ngIf="column.editType === 'file'" href="javascript:void(0)"
               (click)="downloadFileData(row.ID, row[column.field + 'Id'])">{{ outputRowCellValue(column, row) }}</a>
            <span *ngIf="column.editType !== 'file'">{{ outputRowCellValue(column, row) }}</span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
