<p-dialog [(visible)]="cmpVisible" #dlg
          [modal]="true" [style]="dialogStyle" [minY]="70" (onHide)="onHide()" (onShow)="onShow()"
          [maximizable]="configService.isDesktopDevice" [baseZIndex]="8000" (onMaximize)="onMaximize($event)">
  <div class="dialog-wrapper row no-gutters">
    <div class="col-12">
      <p-toolbar [ngClass]="getToolbarClass()">
        <ng-container *ngIf="configService.isDesktopDevice">
          <div class="p-toolbar-group-left">
            <button pButton type="button" icon="pi pi-save" (click)="save(true)"
                    [disabled]="isDisabledBtnDocSave"
                    *ngIf="showBtnSave"
                    pTooltip="Save the document data"></button>
            <button pButton type="button" icon="pi pi-check" (click)="check()"
                    [disabled]="isDisabledBtnDocCheck" *ngIf="showBtnCheck"
                    pTooltip="Check the document data"></button>
            <!-- <button pButton type="button" icon="pi pi-trash" (click)="delete()"
                    *ngIf="showBtnRemove"
                    [disabled]="isDisabledBtnDocRemove"
                    pTooltip="Remove the document"></button> -->
            <button pButton type="button" icon="fa fa-lock-open"
                    *ngIf="showBtnReset"
                    [disabled]="isDisabledBtnDocReset" (click)="reset()"
                    pTooltip="Reopen the submitted document"></button>


            <button pButton type="button" icon="pi pi-clone" (click)="copyLink()"
                    *ngIf="showBtnCopyLink" [disabled]="isDisabledBtnCopyLink"
                    pTooltip="Copy public link"></button>
            <button *ngIf="showBtnPrint" pButton type="button" icon="pi pi-file-pdf" (click)="print2pdf()"
                    [disabled]="isDisabledBtnPdf"
                    pTooltip="Generate PDF file"></button>
          </div>
        </ng-container>

        <ng-container *ngIf="!configService.isDesktopDevice">
          <div class="p-toolbar-group-left">
            <button pButton type="button" icon="fa fa-floppy-o" label="Save"
                    *ngIf="showBtnSave"
                    (click)="save(true)" [disabled]="isDisabledBtnDocSave"></button>
            <button pButton type="button" icon="fa fa-check" label="Check"
                    *ngIf="showBtnCheck"
                    (click)="check()" [disabled]="isDisabledBtnDocCheck"></button>
          </div>
        </ng-container>
      </p-toolbar>
    </div>
    <div class="col-12">
      <!-- Sidebar Holder -->
      <nav class="dialog-sidebar" *ngIf="packagesVisible">
        <ul class="list-unstyled">
          <li *ngFor="let dctDoc of docsItems"
              [class.active]="dctDoc.main ? (dctDoc.items[0].iddoc === iddoc && cdoc === dctDoc.cdoc) : (iddoc === '-1' && cdoc === dctDoc.cdoc)">
            <div class="row no-gutters align-items-center pb-1">
              <div class="col" [class.font-weight-bold]="dctDoc.items?.length">
                <a *ngIf="dctDoc.main"
                   (click)="showDocument(dctDoc.items[0].iddoc, dctDoc.items[0].cdoc)">{{ dctDoc.docLabel }}</a>
                <span *ngIf="!dctDoc.main">{{ dctDoc.docLabel }}</span>
              </div>
              <div class="col-auto">
                <button *ngIf="dctDoc.addVisible" pButton pRipple type="button" icon="pi pi-plus"
                        class="p-button-rounded p-button-text d-inline float-right"
                        (click)="addDocument(dctDoc.cdoc)"></button>
              </div>
            </div>
            <ul *ngIf="!dctDoc.main" class="list-unstyled child-components">
              <li *ngFor="let item of dctDoc.items" [class.active]="item.id === iddoc">
                <div class="row no-gutters">
                  <div class="col">
                    <a (click)="showDocument(item.id, item.cdoc)">{{ item.docLabel }}</a>
                  </div>
                  <div class="col-auto">
                    <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-text d-inline float-right p-0 m-0"
                            style="height: 1.7rem"
                            (click)="deleteDocument(item.id)"></button>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <!-- Page Content Holder [style.height.px]="contentHeight" -->
      <div class="dialog-content" [class.dm-document-package]="packagesVisible" [ngStyle]="contentStyle">
        <document-editor (onResize)="onResize()"></document-editor>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Popup for field definition  -->
<p-dialog header="{{popupHeader}}" [(visible)]="popupShow" [modal]="true"
          [style]="popupType === 'info' ? { 'max-width':'400px', 'width':'400px', 'max-height':'250px'} : { 'max-width':'800px', 'width':'800px'}"
          [baseZIndex]="12000">
  <div [innerHTML]="popupContent"></div>
</p-dialog>
<!-- -->

<app-uploader [src]="dataMode===3?'stage-data-upload':'document-editor'" [iddoc]="iddoc" [cdoc]="cdoc"
              (onSuccess)="fileUploadSuccess($event)"
              (onFailed)="fileUploadFailed($event)"></app-uploader>

<app-orderlist src="document-editor" [iddoc]="iddoc" [cdoc]="cdoc"
               (onSave)="orderListSuccess($event)"
               (onClose)="onclose()"></app-orderlist>

<p-dialog #rowEditDialog id="dynTabRowEditWrapper" [maximizable]="true"
          header="Details" [(visible)]="rowEditDialogVisible" [modal]="true"
          styleClass="dm-document-row-edit-dialog"
          [baseZIndex]="12000">
  <div id="dynTabRowEdit" class="col-12"></div>
</p-dialog>

<ng-template appDynamic></ng-template>
