import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from 'src/app/_services/config.service';
import {Observable} from 'rxjs';
import {AppMessageService} from '../../../_services/app-message.service';

@Injectable()
export class DocumentUtilsPrivateService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) {
  }

  createCheck(cdoc: string): Observable<any> {
    return this.http.post<any>(`${this.configService.api.docutl.link}/create/check`, cdoc);
  }

  createEmptyReport(cdoc: string, datamode?: number): Observable<any> {
    return this.http.post<any>(`${this.configService.api.docutl.link}/create/empty?datamode=${datamode}`, cdoc);
  }

  createAppendix(idpar: string, cdoc: string): Observable<any> {
    const _body = {cdoc: cdoc};
    return this.http.post<any>(`${this.configService.api.docutl.link}/${idpar}/subdocs/add/${cdoc}`, _body);
  }

  save(idpar: string, iddoc: string, cdoc: string, vars: any, data: any, logs: any) {
    const _body = {
      'idref': idpar,
      'iddoc': iddoc,
      'cdoc': cdoc,
      'data': data,
      'logs': logs,
      'dataVars': JSON.stringify(vars)

    };
    return this.http.put<any>(`${this.configService.api.docutl.link}/save`, _body);
  }

  saveWithoutValidate(iddoc: string, vars: any, data: any) {
    const json: any = {
      'iddoc': iddoc,
      'data': data,
      'logs': [],
      'dataVars': JSON.stringify(vars)
    };
    return this.http.put<any>(`${this.configService.api.docutl.link}/save/without_check`, json);
  }

  check(idDoc: string, idPar: string, cdoc: string, data: any, logs: any) {
    const body = {idDoc, idPar, cdoc, data, logs};
    return this.http.post<any>(`${this.configService.api.docutl.link}/check`, body);
  }

  duplicate(ids: string[]): Observable<void> {
    return this.http.post<void>(`${this.configService.api.docutl.link}/duplicate`, ids);
  }

  delete(iddoc: string) {
    return this.http.delete<any>(`${this.configService.api.docutl.link}/${iddoc}/del`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
  }

  send(iddoc: string) {
    return this.http.post<any>(`${this.configService.api.docutl.link}/${iddoc}/send`, null);
  }

  approveReject(iddoc: string, status: string, datamode: number) {
    if ((status === 'under_review') || (status === 'accept') || (status === 'decline') || (status === 'uploaded')) {
      return this.http.post<any>(`${this.configService.api.docutl.link}/${iddoc}/${status}?datamode=${datamode}`, null);
    }
  }

  approveRejectBatch(ids: string[], status: string, datamode: number) {
    if ((status === 'under_review') || (status === 'accept') || (status === 'decline') || (status === 'uploaded')) {
      return this.http.post<any>(`${this.configService.api.docutl.link}/docstage-status?datamode=${datamode}&status=${status}`, ids);
    }
  }

  sendForce(iddoc: string) {
    return this.http.post<any>(`${this.configService.api.docutl.link}/${iddoc}/send/force`, null);
  }

  reset(iddoc: string) {
    return this.http.post<any>(`${this.configService.api.docutl.link}/${iddoc}/edit`, null);
  }

  getDocumentStatus(iddoc: string, cdoc?: string) {
    return this.http.get<any>(`${this.configService.api.docutl.link}/${iddoc}/status?cdoc=${cdoc}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getDocumentJSONData(iddoc: string, idPar: string, cdoc: string, callbackErr: (err) => any) {
    return this.http.get<any>(`${this.configService.api.docutl.link}/${iddoc}/data/json?idPar=${idPar || ''}&cdoc=${cdoc || ''}`)
      .toPromise()
      .catch(err => {
        if (callbackErr) {
          callbackErr(err);
        }
        this.messageService.showMessage(err);
      });
  }


  getListImport4docref(iddocref: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.import}/${iddocref}/ref`);
  }


  getListCharts(charts: string[]) {
    return this.http.post<any>(`${this.configService.api.dicts.charts}`, charts);
  }

  getListSubdocs(iddoc: string) {
    return this.http.get<any>(`${this.configService.api.docutl.link}/${iddoc}/subdocs`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getDocumentToken(iddoc: string, type?: number) {
    return this.http.get<any>(`${this.configService.api.docutl.link}/${iddoc}/get-public-link?type=${type || 0}`);
  }

  getFileLink(iddoc: string, id: string): Observable<string> {
    return this.http.get(`${this.configService.api.fs}/docs/${iddoc}/files/${id}/link`, {responseType: 'text'});
  }

  changeOwner(iddoc: string, iduser: string): Observable<any> {
    return this.http.post<any>(`${this.configService.api.docutl.link}/${iddoc}/owner-change`, iduser);
  }

  markViewed(newDocs: any[]): Observable<void> {
    return this.http.post<void>(`${this.configService.api.docutl.link}/mark-viewed`, newDocs);
  }

  checkDoc(idDoc: string) { // getQueryDocuments
    return this.http.get<any>(`${this.configService.api.rule}/${idDoc}/check`);
  }
}
