import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '../../../_services/config.service';
import {HttpClient} from '@angular/common/http';
import {AppMessageService} from '../../../_services/app-message.service';

@Injectable()
export class BuilderUtilsService {
  constructor(private http: HttpClient,
              private messageService: AppMessageService,
              private configService: ConfigService) {
  }

  getBuilderTemplates(id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.dicts.templates}/${id}/list`);
  }

  getBuilderTemplateParams(id: string) {
    return this.http.get<any>(`${this.configService.api.dicts.templates}/${id}/params`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  saveBuilderTemplate(id: string, params: any): Observable<any> {
    return this.http.post<any>(`${this.configService.api.dicts.templates}/${id}`, params || {});
  }

  deleteBuilderTemplate(id: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.dicts.templates}/${id}`);
  }

  getBuilderParams(id: string, datamode: number) {
    return this.http.get<any>(`${this.configService.api.dicts.builder}/${id}/vars?datamode=${datamode || '0'}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getBuilderTabParams(id: string, cdoc: string, tabn: number) {
    return this.http.get<any>(`${this.configService.api.dicts.builder}/${id}/vars-${tabn}?cdoc=${cdoc || ''}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getQueryDocuments2(idProject: string, fields?: any, _filter?: string) {
    const vars = {ids: null, fields: fields};
    return this.http.post<any>(`${this.configService.api.builder}/${idProject}/query/data2?` + (_filter || ''), vars)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getQueryDocumentsTabData(idProject: string, ids: any, fields?: any) {
    const vars = {ids: ids, fields: fields};
    return this.http.post<any>(`${this.configService.api.builder}/${idProject}/query/tab-data`, vars)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getQueryDocumentsSubDocsData(idProject: string, ids: any) {
    const vars = {ids: ids};
    return this.http.post<any>(`${this.configService.api.builder}/${idProject}/query/subdocs-data`, vars)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  getDocumentTableData(id: string, vars: any, _filter?: string): Observable<any> {
    let paramsStr = '&' + (_filter || '');
    return this.http.post<any>(`${this.configService.api.builder}/${id}/tab-data?${paramsStr}`, vars);
  }

  getFileLink(iddoc: string, id: string): Observable<string> {
    return this.http.get(`${this.configService.api.fs}/docs/${iddoc}/files/${id}/link`, {responseType: 'text'});
  }
}
