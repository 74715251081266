import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {map, retry} from 'rxjs/operators';
import {Base64} from 'js-base64';
import * as Base64js from 'base64-js';
import {DataService} from './data.service';
import {AppMessageService} from '../../_services/app-message.service';
import {HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  @Input()  private src: string;
  @Input()  private iddoc: string;
  @Input()  private cdoc: string;


  @Output()
  private onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onFailed: EventEmitter<any> = new EventEmitter<any>();
  visible: boolean;
  private _fileName: string;
  @ViewChild('fileCmp', {static: false})
  private fileCmp: FileUpload;
  /**
   * 0 - before uploading, 1 - uploading, 2 - succeed, 3 - failed
   */
  private _status: number;
  private _uploadProgress: number;
  private _dicomTags: any;
  private rowId: string;

  constructor(private dataService: DataService,
              private messageService: AppMessageService) {
  }

  ngOnInit(): void {
  }

  show(rowId: string) {
    this.rowId = rowId;
    this._status = 0;
    this._dicomTags = {};
    this._uploadProgress = 0;
    this.visible = true;
    this.fileCmp.clear();
  }

  endsWith(str, suffix) {
    return str.toLowerCase().indexOf(suffix.toLowerCase(), str.length - suffix.length) !== -1;
  }

  fileUploader(event) {
    const fileList: FileList = event.files;
    if (fileList.length === 1) {
      const file: File = fileList[0];
      const fileType = file.type || 'application/octet-stream';
      this._fileName = file.name;
      this._status = 1;
      if (this.src === 'document-editor') {
        this.dataService.docBeforeUpload(this.iddoc)
          .subscribe(
            id => {
              const fr = new FileReader();
              fr.onload = () => {
                this.upload(this.rowId, this.cdoc, this.iddoc, id, file.name, fileType, <ArrayBuffer>fr.result);
              };
              fr.readAsArrayBuffer(file);
            },
            error => {
              this.messageService.showMessage(error, '[fileUploader]');
              this._status = 3;
              this.onFailed.emit();
            });
      } else if (this.src === 'public-document-editor') {
        this.dataService.publicDocBeforeUpload(this.iddoc)
          .subscribe(
            id => {
              const fr = new FileReader();
              fr.onload = () => {
                this.upload(this.rowId, this.cdoc, this.iddoc, id, file.name, fileType, <ArrayBuffer>fr.result);
              };
              fr.readAsArrayBuffer(file);
            },
            error => {
              this.messageService.showMessage(error, '[fileUploader]');
              this._status = 3;
              this.onFailed.emit();
            });
      } else if (this.src === 'stage-data-upload') {
        this.dataService.stageBeforeUpload(this.iddoc)
          .subscribe(
            id => {
              const fr = new FileReader();
              fr.onload = () => {
                this.upload(this.rowId, this.cdoc, this.iddoc, id, file.name, fileType, <ArrayBuffer>fr.result);
              };
              fr.readAsArrayBuffer(file);
            },
            error => {
              this.messageService.showMessage(error, '[fileUploader]');
              this._status = 3;
              this.onFailed.emit();
            });
      }
    }
  }

  private async upload(fieldname: string, cdoc: string, iddoc: string, id: string, fileName: string, fileType: string, fileData: ArrayBuffer) {
    const chunkSize = this.src === 'stage-data-upload'?fileData.byteLength:1024 * 1024;
    let chunkInd = 1;
    const base64BlockIds: string[] = [];
    let cntCompleted = 0;
    const fileLength = fileData.byteLength;
    const totalLength = fileLength * 1.35;
    let uploadedLength = 0;
    const chunkCnt = Math.ceil(fileLength / chunkSize);
    for (let offset = 0; offset < fileLength; offset += chunkSize) {
      const chunk = fileData.slice(offset, offset + chunkSize);
      const base64BlockId = Base64.encode((chunkInd + '').padStart(10, '0'));
      base64BlockIds.push(base64BlockId);
      let chunkLength;
      try {
        if (this.src === 'document-editor') {
          const res = await this.dataService.docUpload(iddoc, id, {
            base64BlockId: base64BlockId,
            numberOfChunk: this.endsWith(fileName, ".dcm") ? cntCompleted : -1,
            cdoc: cdoc,
            fieldname: fieldname,
            src: this.src,
            data: Base64js.fromByteArray(new Uint8Array(chunk))
          }).pipe(
            retry(3),
            map(
              (event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this._uploadProgress = Math.round((100 / totalLength) * (uploadedLength + event.loaded));
                  chunkLength = event.total;
                } else if (event.type === HttpEventType.Response) {
                  uploadedLength += chunkLength;
                }
                return event;
              }
            )).toPromise();
          if ((cntCompleted == 0)) {
            this._dicomTags = res.body;
          }

        } else if (this.src === 'public-document-editor') {
          const res = await this.dataService.publicDocUpload(iddoc, id, {
            base64BlockId: base64BlockId,
            numberOfChunk: this.endsWith(fileName, ".dcm") ? cntCompleted : -1,
            cdoc: cdoc,
            fieldname: fieldname,
            src: this.src,
            data: Base64js.fromByteArray(new Uint8Array(chunk))
          }).pipe(
            retry(3),
            map(
              (event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this._uploadProgress = Math.round((100 / totalLength) * (uploadedLength + event.loaded));
                  chunkLength = event.total;
                } else if (event.type === HttpEventType.Response) {
                  uploadedLength += chunkLength;
                }
                return event;
              }
            )).toPromise();
          if ((cntCompleted == 0)) {
            this._dicomTags = res.body;
          }
        } else if (this.src === 'stage-data-upload') {
          const res = await this.dataService.stageUpload(iddoc, id, {
            base64BlockId: base64BlockId,
            numberOfChunk: -1,
            cdoc: cdoc,
            fieldname: fieldname,
            filename: fileName,
            src: this.src,
            data: Base64js.fromByteArray(new Uint8Array(chunk))
          }).pipe(
            retry(3),
            map(
              (event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this._uploadProgress = Math.round((100 / totalLength) * (uploadedLength + event.loaded));
                  chunkLength = event.total;
                } else if (event.type === HttpEventType.Response) {
                  uploadedLength += chunkLength;
                }
                return event;
              }
            )).toPromise();
        }
        cntCompleted++;
        if (cntCompleted === chunkCnt) {
          this.onSuccess.emit({
            id: id,
            name: fileName,
            type: fileType,
            blocks: base64BlockIds.join(','),
            dicomTags: this._dicomTags
          })
        }
      } catch (error) {
        this.messageService.showMessage(error, '[fileUploader]');
        this._status = 3;
        this.onFailed.emit();
      }
      chunkInd++;
    }
  }

  finish(succeed: boolean) {
    this._uploadProgress = 100;
    this._status = succeed ? 2 : 3;
  }

  get fileName(): string {
    return this._fileName;
  }

  get status(): number {
    return this._status;
  }

  get uploadProgress(): number {
    return this._uploadProgress;
  }
}
