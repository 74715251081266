import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {RespOnProcessDoc} from 'src/app/_domains/UITypes';
import {ConfigService} from '../../../_services/config.service';
import {ConfirmationService} from 'primeng/api';
import {AppMessageService} from '../../../_services/app-message.service';
import {ViewDictPrivateService} from '../_services/view-dict.service';

declare var $: any;
declare var DocumentInit: any;
declare var pz: any;
declare var ScriptEnv: any;

@Component({
  selector: 'document-editor',
  templateUrl: './document-editor.component.html'
})
export class DocumentEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showErrors: boolean = false;
  @Input() classContent: string = '';
  @Output() onProcess = new EventEmitter<RespOnProcessDoc>();
  @Output() onResize = new EventEmitter<void>();

  templateHtml: string;
  errors: any = [];
  errorsSteps: any = [];

  private idpar: string;
  private access: any = null;
  private cdoc: string = '';
  private resizeObserver: any;

  constructor(private configService: ConfigService,
              private confirmationService: ConfirmationService,
              private messageService: AppMessageService,
              private dictService: ViewDictPrivateService,
              private ngZone: NgZone) {
    this.templateHtml =
      `<div style="top:45%; left:45%; vertical-align: center;">
        <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
      </div>`;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let _self = this;
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    $.extend($.jdm, {
      // get dictionary data
      getDictData: function (dictName, valueField, parentDictName, parentValueField, parentValue, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getDictData(dictName, valueField, parentDictName, parentValueField, parentValue).then(data => {
            if (clb) clb(data);
          });
        });
      }
    });

    let _debounce = function (ms, fn) {
      let timer;
      return function () {
        clearTimeout(timer);
        let args = Array.prototype.slice.call(arguments);
        args.unshift(this);
        timer = setTimeout(fn.bind.apply(fn, args), ms);
      };
    };
    this.resizeObserver = new ResizeObserver(_debounce(500, entries => this.onResize.emit()));
  }

  ngOnDestroy(): void {
    this.dropResizeObserver();
  }

  setTemplate(tmpl) {
    this.templateHtml = tmpl;
  }

  private dropResizeObserver() {
    if (this.resizeObserver && document.getElementById('DocContent')) {
      this.resizeObserver.unobserve(document.getElementById('DocContent'));
    }
  }

  private reInitResizeObserver() {
    this.dropResizeObserver();
    setTimeout(() => {
      if (document.getElementById('DocContent')) {
        this.resizeObserver.observe(document.getElementById('DocContent'));
      }
    }, 100);
  }

  refreshVars(iddoc: string,
              _docStatus: any,
              _docVars: any,
              _authUser: any,
              callbackDcoData: (success, failure) => any) {
    this.reInitResizeObserver();
    this.idpar = iddoc;
    this.cdoc = _docStatus.cdoc;
    this.templateHtml = _docVars.htmlTemplate;
    this.access = _docStatus.optsAcc || {};
    pz = null;
    pz = {
      $inst: null,
      cdoc: _docStatus.cdoc,
      iddoc: this.idpar,
      docState: _docStatus.docState || 0,
      inputs: _docVars.params,
      rules: _docVars.rules,
      groups: _docVars.groups,
      sprDoc: _docVars.sprdoc,
      mode: _docStatus.editable || false,
      newRpt: _docStatus.newRpt,
      authuser: _authUser,
      columnModel: JSON.parse(_docVars.colModel),
      dataVars: _docStatus.dataVars,
      access: this.access,

    };

    setTimeout(function () {
      DocumentInit(pz, false, function (success, failure) {
        callbackDcoData(success, failure)
      });
    }, 100);
  }

  commit() {
    (pz.$inst).jqDm('commit');
  }

  reset() {
    this.errors = [];
    this.errorsSteps = [];
    if (pz?.$inst) {
      (pz.$inst).jqDm('resetCellsErr');
    }
  }

  isCommitted() {
    return (pz.$inst).jqDm('isCommitted');
  }

  data() {
    return (pz.$inst).jqDm('getDocumentData', pz);
  }


  check() {
    let App = this,
      $inst = pz.$inst,
      rows = ($inst.jqDm('check')).rows,
      env = new ScriptEnv($inst, 0, 0);
    rows = env.validate(App, rows);
    return {valid: rows.length === 0, errors: rows};
  }

  parseLogs(logs: any) {
    this.errors = [];
    this.errorsSteps = [];
    if (logs.length > 0) {
      let $inst = pz.$inst, r, rnum, tp, type, chk;
      const errFieldNames = logs.map(v => v.field);
      const tableCols = $inst[0].p.colModel
        .filter(v => v.tabn !== 0 && !v.rownum && !v.hidden && v?.useropts?.dynTabForm?.secondary !== 0 &&
          errFieldNames.indexOf(v.index) >= 0);
      $inst.jqDm('resetTableEditStatus');
      logs.filter(v => tableCols.find(t => t.index === v.field))
        .map(v => ({rn: v.rowNum, tabn: tableCols.find(t => t.index === v.field).tabn}))
        .forEach(v => $inst.jqDm('setTableEditStatus', v.tabn, v.rn, 'error'));
      this.errorsSteps = Array.from(new Set(logs.filter(v => v?.grpType > 0).map(v => v.grpType - 1)));
      let steps = []
      $('.dm-wizard li.nav-item:not(li.nav-item.hidden) a.nav-link').each(function () {
        const v = this;
        const href = v.href || '';
        steps.push(+href.substring(href.indexOf('#step') + 5));
      });
      if (steps.length > 0) {
        $inst[0].p.tabsViewed = steps;
      }


      for (let obj in logs) {
        r = logs[obj];
        rnum = r.rowNum;
        tp = r.typeCheck;
        type = '';
        if (tp === 3) { // Специально для xsd валидации
          const pos = $inst[0].getColIndex(r.field), cm = $inst[0].p.colModel, data = $inst[0].p.data;
          if (pos !== -1) {
            const fld = cm[pos], tabn = fld.tabn || 0;
            if (tabn !== 0 && data[tabn].length !== 1) {
              rnum = -1;
            }
          } else {
            rnum = -1;
          }
        }

        switch (tp) {
          case 1:
            type = 'Integrity check';
            break;
          case 2:
            type = 'Inspection check';
            break;
          case 3:
            type = 'XSD check';
            break;
        }
        //ig added error type to params
        chk = $inst.jqDm('setCellStatus', rnum, r.field, 'error', '<span class=\'type\'>' + type + '</span>: ' + r.message, r?.errorType, r?.grpType);
        if (chk === -2) {
          const cm = $inst[0].p.colModel;
          $(cm).each(function () {
            const _fld = this, _parent = _fld.useropts && _fld.useropts.parent ? _fld.useropts.parent : null;
            if (_parent) {
              if (_parent.name === r.field) {
                chk = 0;
                //ig added error type to params
                $inst.jqDm('setCellStatus', rnum, _fld.index, 'error', '<b>' + type + '</b>: ' + r.message, r?.errorType, r?.grpType);
                return true;
              }
            }
          });

        }
        if (chk < 0 || rnum === -1) {
          this.errors.push({rnum: rnum, type: type, field: r.field, grpType: r.grpType, message: r.message});
        }
      }
    }
  }

  deattachCmp() {
    this.reset();
    this.templateHtml =
      `<div style="top:45%; left:45%; vertical-align: center;">
        <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
      </div>`;
    if (pz) {
      pz.$inst = null;
    }
    pz = null;
  }

  print2pdf(fileName: string, callback: () => any) {
    (pz.$inst).jqDm('export2pdf', fileName, callback);
  }

  restoreEditor() {
  }

  setStatus(resp: any) {
    pz.mode = resp.editable;
    pz.newRpt = resp.newRpt;
    pz.docState = resp.docState || 0;
    pz.$inst.jqDm('setDocStatus', resp.editable === false);
  }

  setCellValue(nm, val) {
    pz.$inst.jqDm('setCellValue', 0, nm, val);
  }

  clearFileDataInt(rowId: string) {
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Id', '');
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Type', '');
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Blocks', '');
    pz.$inst.jqDm('setCellValue', 0, rowId, '');
  }

  orderListSuccess(rowId: string, data: any) {
    let res = '';
    for (let i = 0; i < data.length; i++) {
      if (res !== '') res += ';';
      res += data[i].value;
    }
    pz.$inst.jqDm('setCellValue', 0, rowId, res);
  }

  fileUploadSuccess(rowId: string, data: any) {
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Id', data.id);
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Type', data.type);
    pz.$inst.jqDm('setCellValue', 0, rowId + 'Blocks', data.blocks);
    pz.$inst.jqDm('setCellValue', 0, rowId, data.name);
    if ((data.dicomTags !== null) && (Object.keys(data.dicomTags).length !== 0)) {
      Object.entries(data.dicomTags).forEach(function (value) {
        pz.$inst.jqDm('setCellValue', 0, value[0], value[1]);
      });
    }
  }


  dynamicVars() {
    return {
      dynamicForm: pz.dynamicForm || false,
      dynamicVars: pz.dynamicVars || {}
    }
  }
}
